import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import HOC from "../../img/hoc.png";
import MusicApp from "../../img/musicapp.png";
import { themeContext } from "../../Context";
import foto2 from "../../img/foto2.jpeg";
import foto3 from "../../img/foto3.jpeg";
import foto4 from "../../img/foto4.jpeg";
import foto5 from "../../img/foto5.jpeg";
import foto6 from "../../img/foto6.jpeg";


const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Galeri</span>
      <span>Projeler</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={foto2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={foto3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={foto4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={foto5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={foto6} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
