import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import Gitub from "@iconscout/react-unicons/icons/uil-github";
import { AiFillPhone } from "react-icons/ai";


const Footer = () => {
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <div>
          <h1 id="footer-adress-header">Emk Prefabrik Ofis</h1>
          <p>Yeşiloba Mahallesi 46067 Sk. No:15 Seyhan/Adana</p>
        </div>
        <div>
          <h1 id="footer-adress-header">Emk prefabrik İmalat</h1>
          <p>Sarıhamzalı Mahallesi 47011 Sokak No:9-A Seyhan/Adana</p>
        </div>
        <div>
          <h1 id="footer-adress-header">Sosyal Medya</h1>
          <Insta color="white" size={"2rem"} />
          <Facebook color="white" size={"2rem"} />

        </div>
        <div>
          <h1 id="footer-adress-header">Telefon</h1>
          <div className="iletişimnumarası">
          <AiFillPhone/>
          <a href="+905011316591">0501 131 65 91</a>
          </div>

          <div className="iletişimnumarası">
          <AiFillPhone/>
          <a href="+905324769588">0532 476 95 88</a>
          </div>
          
          
        </div>
      </div>
    </div>
  );
};

export default Footer;


{/* <div className="footer">
<img src={Wave} alt="" style={{ width: "100%" }} />
<div className="f-content">
  <span>emkprefabrik@gmail.com</span>
  <div className="f-icons">
    <Insta color="white" size={"3rem"} />
    <Facebook color="white" size={"3rem"} />
    <Gitub color="white" size={"3rem"} />
  </div>
</div>
</div> */}