import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";
import profil1 from "../../img/profil1.jpg";
import profil2 from "../../img/profil2.jpg";
import profil3 from "../../img/profil3.jpg";
import profil4 from "../../img/profil4.jpg";
import profil5 from "../../img/profil5.jpg";
import profil6 from "../../img/profil6.jpg";
import profil7 from "../../img/profil7.jpg";
import profil8 from "../../img/profil8.jpg";
import profil9 from "../../img/profil9.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profil1,
      review:
        "Prefabrik ev kurulumunun yapılacağı arazinin gerekli izinlerinin alınmış olması. Bunun için arsanın bulunduğu bölgenin, bağlı olduğu belediyeden arazinin imar iznini teyit edip onay almalısınız.",
    },
    {
      img: profil2,
      review:
        "Arsanızın imar izni ile ilgili süreç tamamlandıktan sonra bir de yapı ruhsatı almanız gerektiğini söyleyebiliriz. Arazinin tapu, ruhsat ve izinlerini tamamladıktan sonra ise prefabrik ev kurulumu için sonraki adımlara geçebilirsiniz.",
    },
    {
      img: profil3,
      review:
        "Prefabrik evinizin kurulacağı arazi için gerekli ilk aşama tamamlandıktan sonra proje ve planlar göz önüne alınarak tek katlı ya da çift katlı seçeneklerden hangisinin ihtiyacınızı karşılayacağına karar vermeniz gerekir. Kararınızın ardından anlaştığınız prefabrik yapı firması, prefabrik ev için gereken malzemelerin imalat sürecine fabrika ortamında başlayacaktır.",
    },
    {
      img: profil4,
      review:
        "Prefabrik evler güvenliği temel alan yapılar oldukları için en fazla çift katlı yapılabilmektedir. Estetik açıdan ilgi çeken bir niteliğe sahip olan bu evler, buna ek olarak betonarme yapılar kadar uzun bir inşaat sürecine de sahip olmadığı için diğer yapılardan kolaylıkla farklılaşıyorlar.",
    },
    {
      img: profil5,
      review:
        "Prefabrik ev kurulumu için arazi zemininin detaylı incelenmesi gerekmektedir. Eğer zemin düzse, zemine direkt olarak beton dökülerek prefabrik evin kurulumuna başlanabilir. Ancak eğimler söz konusuysa ve zemin sıkı bir yapıya sahip değilse yapının kurulumu gerçekleştirilemeyeceği için birtakım düzenlemeler yapılması gerekebilir.",
    },
    {
      img: profil6,
      review:
        "Bu düzenlemelerle arazinin zemini güçlendirebilmek için doldurulabilir ya da istinat duvarı çekilebilir. Arazi uygun bir zemin formuna getirildikten sonra ise zemine yine beton dökülür. Zeminin yapısı, prefabrik evin kullanım süresi için son derece önemli bir konudur. ",
    },
    {
      img: profil7,
      review:
        "Fabrikada üretilen parçaların kurulum ve montajı yapılır ve üretilen parçalar sahaya geldiğinde montaj işlemi genellikle birkaç gün içerisinde tamamlanır. Montaj süreci genel hatlarıyla  geleneksel betonarme yapılardan çok daha kısa bir sürede profesyonel ve hızlı ekipler tarafından tamamlandığını bir kez daha vurgulamış olalım.",
    },
    
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Prefabrik Ev </span>
        <span>Yapım </span>
        <span> Aşamaları</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
