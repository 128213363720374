import React, { useContext } from "react";
import "./Services.css";
import Card from "../Card/Card";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Resume from './resume.pdf';
import deprem from "../../img/deprem.png";
import sesyalitimi from "../../img/sesyalitimi.png";
import zaman from "../../img/zaman.png";

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>Prefabrik Evlerin</span>
        <span>Avantajları Nedir?</span>
        <span id="prefabrik-text">
        Prefabrik yapılar sayesinde yapılan sisteme ve anlaşmaya bağlı olarak minimum 2 hafta 
        <br/>
        maksimum 2 ay içerisinde ev sahibi olunabilmekte ve işçilik maliyetleri çok daha uygun olmaktadır. 
          <br />
          Modüler olarak üretilen prefabrik evler sayesinde öncesinde istenildiği şekilde üretimi yapılır.
          <br/>
          Prefabrik evlerinin en önemli özelliklerinden biri de prefabrik yapılacak olan bölgenin tüm iklim ve deprem verileri incelenerek o bölgeye uygun olarak tasarlanmasıdır.
          <br/>
          Prefabrik evler standart betonarme yapılara göre 10 kat ses ve ısı yalıtım özelliğine sahip sistemlerdir.
        </span>
        <a href="https://api.whatsapp.com/send?phone=905011316591" target="_blank" download>
          <button className="button s-button">Canlı Destek</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      {/* right */}
      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{ left: "25rem" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
        >
          <Card
            emoji={sesyalitimi}
            heading={"Ses Yalıtımı"}
            detail={"Isı ve ses yalıtımı özelliğine sahip olduğu için her mevsimde kullanılabilir"}
          />
        </motion.div>
        {/* second card */}
        <motion.div
          initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
        >
          <Card
            emoji={deprem}
            heading={"Deprem"}
            detail={"Hafif malzemelerden üretildiği için prefabrik evler, depreme dayanıklıdır."}
          />
        </motion.div>
        {/* 3rd */}
        <motion.div
          initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}
        >
          <Card
            emoji={zaman}
            heading={"Zaman"}
            detail={
              "Kısa süre içinde montajlanıp oturmaya hazır hale gelir."
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
