import React, { useContext } from "react";
import "./Intro.css";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import boy from "../../img/boy.png";
import glassesimoji from "../../img/glassesimoji.png";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import emklogo from "../../img/emkpref.png"
import emklogos from "../../img/emk-logo-s.png"
import vektordeneme from "../../img/vektordeneme.png"
import vektordeneme2 from "../../img/vektordeneme2.png"
import { AiFillInstagram,AiFillFacebook,AiFillTwitterCircle } from "react-icons/ai";



const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          <span style={{ color: darkMode ? "white" : "" }}>Hakkımızda</span>
          <span>Emk Prefabrik</span>
          <span>
          Prefabrik evler, hızlı inşaat süreleri, çevre dostu malzemeler ve tasarım esnekliği gibi birçok avantaj sunar. Biz , prefabrik evler konusunda uzmanlaşmış bir firma olarak, müşterilerimize konforlu, estetik ve dayanıklı konutlar sunmayı hedefliyoruz.
          </span>
          <span>
          Ofis Adresi : Yeşiloba Mahallesi 46067 Sk. No:15 Seyhan/Adana
          </span>
          <span>
          İmalat Adresi :Sarıhamzalı Mahallesi 47011 Sokak No:9-A Seyhan/Adana
          </span>
        </div>
        {/* <Link to="https://wa.me/905453396787" smooth={true} spy={true}>
          <a href="https://api.whatsapp.com/send?phone=905453396787" className="button i-button">İletişim</a>
        </Link> */}
        <a href="https://api.whatsapp.com/send?phone=905011316591" target="_blank" className="button i-button">Canlı Destek</a>
        {/* social icons */}
        <div className="i-icons">
          
          <AiFillInstagram size={108}  className="Intro-React-Icons"/>
          <AiFillFacebook size={108}  className="Intro-React-Icons"/>
          <AiFillTwitterCircle  size={108}  className="Intro-React-Icons"/>
        </div>
      </div>
      {/* right image side */}
      <div className="i-right">
        {/* <img src={Vector1} alt="" />
        <img src={Vector2} alt="" /> */}
         {/* <img src={boy} alt="" />  */}
       
        {/* animation */}
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-7%" }}
          transition={transition}
          src={emklogos}
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={crown} text1="Zamanında " text2="Teslim" />
        </motion.div>

        {/* animation */}
        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Hızlı " text2="Çözümler" />
        </motion.div>

        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;
